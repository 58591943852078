import PropTypes from 'prop-types';

import { contentfulFormType } from 'humanity/components/contentfulForm/contentfulForm.types';
import { ctaType } from 'humanity/components/cta/cta.types';
import { simpleRichTextType } from 'humanity/components/simpleRichText/simpleRichText.types';
import { contentfulMediaType } from 'types/common/contentfulMedia.types';
import { contentfulSysType } from 'types/common/contentfulSys.types';

export const basicMastheadProps = {
  variant: PropTypes.oneOf([
    'white',
    'tealGradient',
    'yellow10',
    'brightBlue10',
    'blue100',
  ]),
  image: contentfulMediaType.isRequired,
  imageAlignment: PropTypes.oneOf(['center', 'bottom']).isRequired,
  contentPosition: PropTypes.oneOf(['left', 'right']),
  eyebrow: PropTypes.string,
  heading: PropTypes.string.isRequired,
  paragraph: simpleRichTextType,
  form: contentfulFormType,
  cta: ctaType,
  disclosures: simpleRichTextType,
};

export const basicMastheadDefaultProps = {};

export const basicMastheadType = PropTypes.shape({
  sys: contentfulSysType.isRequired,
  ...basicMastheadProps,
});
