import PropTypes from 'prop-types';

import { contentfulSysType } from 'types/common/contentfulSys.types';

const commonProps = {
  title: PropTypes.string.isRequired,
  showTableHeading: PropTypes.bool.isRequired,
};

export const investmentFundType = PropTypes.shape({
  title: PropTypes.string.isRequired,
  symbol: PropTypes.string.isRequired,
  expenseRatio: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
});

export const investmentFundCategoryProps = {
  ...commonProps,
  funds: PropTypes.arrayOf(investmentFundType).isRequired,
};

export const investmentFundCategoryType = PropTypes.shape({
  ...commonProps,
  sys: contentfulSysType.isRequired,
  fundsCollection: PropTypes.shape({
    items: PropTypes.arrayOf(investmentFundType).isRequired,
  }).isRequired,
});
