import InvestmentFundCategory from 'humanity/components/investmentFundCategory';
import Box from 'humanity/primitives/box';
import Container from 'humanity/primitives/container';
import Heading from 'humanity/primitives/heading';
import Section from 'humanity/primitives/section';
import { investmentFundsProps } from 'humanity/sections/investmentFunds/investmentFunds.types.js';
import { getPaddingValues } from 'utils/getPaddingValues';

const InvestmentFunds = ({ verticalPadding, heading, fundCategories }) => (
  <Section py={getPaddingValues(verticalPadding)}>
    <Container>
      <Box maxWidth={792} mx="auto">
        <Heading
          as="h2"
          variant={['h4', null, null, 'h3']}
          dangerouslySetInnerHTML={{ __html: heading.trim() }}
          css={{ alignSelf: 'flex-start' }}
        />
        {fundCategories.map((category) => (
          <Box key={category.sys?.id} mt={[6, null, null, 7]}>
            <InvestmentFundCategory
              title={category.title}
              funds={category.fundsCollection?.items}
              showTableHeading={category.showTableHeading}
            />
          </Box>
        ))}
      </Box>
    </Container>
  </Section>
);

InvestmentFunds.propTypes = {
  ...investmentFundsProps,
};

export default InvestmentFunds;
