import PropTypes from 'prop-types';

import { simpleRichTextType } from 'humanity/components/simpleRichText/simpleRichText.types';
import { simpleRichTextSectionType } from 'humanity/sections/simpleRichTextSection/simpleRichTextSection.types';
import { investmentFundsType } from 'humanity/sections/investmentFunds/investmentFunds.types';
import { basicMastheadType } from 'humanity/sections/basicMasthead/basicMasthead.types';
import { valuePropListType } from 'humanity/sections/valuePropList/valuePropList.types';

export const advisorsPageProps = {};

export const advisorsPageType = PropTypes.shape({
  sectionsCollection: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.oneOfType([
        basicMastheadType,
        simpleRichTextSectionType,
        investmentFundsType,
        valuePropListType,
      ])
    ),
  }),
  notesCollection: PropTypes.shape({
    items: PropTypes.arrayOf(simpleRichTextType),
  }),
  disclosuresCollection: PropTypes.shape({
    items: PropTypes.arrayOf(simpleRichTextType),
  }),
});
