import PropTypes from 'prop-types';

import { valuePropWithImageType } from 'humanity/components/valuePropWithImage/valuePropWithImage.types';
import { contentfulPaddingType } from 'types/common/contentfulPadding.types';
import { ctaType } from 'humanity/components/cta/cta.types';
import { simpleRichTextType } from 'humanity/components/simpleRichText/simpleRichText.types';

const commonProps = {
  backgroundColor: PropTypes.string,
  topPadding: contentfulPaddingType,
  bottomPadding: contentfulPaddingType,
  heading: PropTypes.string,
  headingAlignment: PropTypes.oneOf(['left', 'center']),
  subheading: PropTypes.string,
  cta: ctaType,
  disclosures: simpleRichTextType,
};

export const valuePropListProps = {
  ...commonProps,
  valueProps: PropTypes.arrayOf(valuePropWithImageType).isRequired,
};

export const valuePropListType = PropTypes.shape({
  ...commonProps,
  valuePropsCollection: PropTypes.shape({
    items: PropTypes.arrayOf(valuePropWithImageType).isRequired,
  }),
});
