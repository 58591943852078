import styled, { css as styledCss } from 'styled-components';
import css from '@styled-system/css';

import Flex from 'humanity/primitives/flex';
import Box from 'humanity/primitives/box';
import Image from 'humanity/primitives/image';
import {
  commonProps,
  valuePropWithImageProps,
} from 'humanity/components/valuePropWithImage/valuePropWithImage.types';
import Heading from 'humanity/primitives/heading';
import SimpleRichText from 'humanity/components/simpleRichText';
import Cta from 'humanity/components/cta';
import Grid from 'humanity/primitives/grid';
import { slideInFromRight } from 'utils/animations';
import { constrainImage } from 'utils/constrainImage';

const slideInAnimation = styledCss`
  animation: ${slideInFromRight(35)} 0.4s ease-in-out
`;

const Circle = styled(Flex)(
  css({
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    borderRadius: 'circle',
    width: 72,
    height: 72,
    bg: 'backgroundLightGray2',
  })
);

const CopyWrapper = styled(Flex)(
  css({
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    height: '100%',
  })
);

const CircleCopyWrapper = styled(CopyWrapper)(
  css({
    ml: [4, null, 5],
  })
);

const TabbedCopyWrapper = styled(Box)(
  css({
    gridColumn: ['1 / span 12', null, '1 / span 5', '1 / span 4'],
    gridRow: '1',
  })
);

const TabbedCtaWrapper = styled(Box)(
  css({
    gridColumn: ['1 / span 12', null, '1 / span 5', '1 / span 4'],
    gridRow: ['3', null, '2'],
    alignSelf: 'start',
  })
);

const TabbedImageWrapper = styled(Flex)(
  css({
    gridColumn: ['1 / span 12', null, '6 / span 7', '6 / span 8'],
    gridRow: ['2', null, '1/4'],
    justifyContent: 'center',
    alignItems: 'flex-start',
  })
);

const Tabbed = ({ heading, content, image, cta, ...rest }) => {
  const { width: imageWidth, height: imageHeight } = constrainImage(
    image.width,
    image.height,
    700
  );

  return (
    <Grid gridRowGap={5} pt={[5, null, null, 7]} {...rest}>
      <TabbedCopyWrapper>
        <Heading variant="h5" dangerouslySetInnerHTML={{ __html: heading?.trim() }} />
        <SimpleRichText mt={3} content={content?.json} />
      </TabbedCopyWrapper>
      <TabbedCtaWrapper>{cta && <Cta location="value_prop" {...cta} />}</TabbedCtaWrapper>
      <TabbedImageWrapper css={slideInAnimation}>
        <Image src={image.url} width={imageWidth} height={imageHeight} />
      </TabbedImageWrapper>
    </Grid>
  );
};

Tabbed.propTypes = {
  ...commonProps,
};

const WithCircle = ({ heading, content, image, cta }) => (
  <Flex height="100%">
    <Circle>
      <Image
        src={image.url}
        width={24}
        height={24}
        imgixParams={{ fit: 'crop', ar: '1:1' }}
      />
    </Circle>
    <CircleCopyWrapper>
      <Box>
        <Heading variant="h5" dangerouslySetInnerHTML={{ __html: heading?.trim() }} />
        <SimpleRichText mt={2} content={content?.json} />
      </Box>
      {cta && <Cta mt={5} location="value_prop" {...cta} />}
    </CircleCopyWrapper>
  </Flex>
);

WithCircle.propTypes = {
  ...commonProps,
};

const Standard = ({ heading, content, image, cta }) => (
  <CopyWrapper>
    <Box>
      <Image
        src={image.url}
        width={48}
        height={48}
        imgixParams={{ fit: 'crop', ar: '1:1' }}
      />
      <Heading
        mt={5}
        variant="h5"
        dangerouslySetInnerHTML={{ __html: heading?.trim() }}
      />
      <SimpleRichText mt={2} content={content?.json} />
    </Box>
    {cta && <Cta mt={5} location="value_prop" {...cta} />}
  </CopyWrapper>
);

Standard.propTypes = {
  ...commonProps,
};

const ValuePropWithImage = ({
  heading,
  image,
  content = null,
  cta = null,
  type = 'standard',
}) => {
  if (type === 'withCircle')
    return <WithCircle heading={heading} content={content} image={image} cta={cta} />;

  if (type === 'tabbed')
    return <Tabbed heading={heading} content={content} image={image} cta={cta} />;

  return <Standard heading={heading} content={content} image={image} cta={cta} />;
};

ValuePropWithImage.propTypes = {
  ...valuePropWithImageProps,
};

export default ValuePropWithImage;
