/* eslint-disable react/no-array-index-key */
import styled from 'styled-components';
import css from '@styled-system/css';

import { investmentFundCategoryProps } from 'humanity/components/investmentFundCategory/investmentFundCategory.types.js';
import VisuallyHidden from 'humanity/components/visuallyHidden';
import Box from 'humanity/primitives/box';
import Heading from 'humanity/primitives/heading';
import Link from 'humanity/primitives/link';
import Table from 'humanity/primitives/table';

const StyledLink = styled(Link)(
  css({
    color: 'blue100',
  })
);

const HeaderCell = styled(Box)(
  css({
    px: 3,
    pb: 2,
    textAlign: 'left',
    fontSize: 'eyebrow',
    lineHeight: 'eyebrow',
    color: 'blue70',
    textTransform: 'uppercase',
    letterSpacing: '1px',
    '&:last-of-type': {
      textAlign: 'right',
    },
  })
);

const TableCell = styled(Table.Cell)(
  css({
    '&:first-child': {
      pl: 3,
    },
    '&:last-child': {
      pr: 3,
    },
  })
);

const HEADINGS = ['Fund Name', 'Symbol', 'Expense Ratio'];

const InvestmentFundCategory = ({ title, showTableHeading, funds }) => (
  <Box>
    <Heading as="h3" variant="h6">
      {title}
    </Heading>
    <Table mt={3} width={1}>
      {showTableHeading && (
        <Table.Header>
          <Table.Row>
            {HEADINGS.map((heading, idx) => (
              <HeaderCell as="th" key={idx}>
                {heading}
              </HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>
      )}
      {!showTableHeading && (
        <VisuallyHidden as="thead">
          <tr>
            {HEADINGS.map((heading, idx) => (
              <th key={idx}>{heading}</th>
            ))}
          </tr>
        </VisuallyHidden>
      )}
      <Table.Body>
        {funds.map((fund, idx) => (
          <Table.Row key={idx}>
            <TableCell width="70%">{fund.title}</TableCell>
            <TableCell width="10%">
              {fund.url && <StyledLink href={fund.url}>{fund.symbol}</StyledLink>}
            </TableCell>
            <TableCell width="20%" css={{ textAlign: 'right' }}>
              {fund.expenseRatio}
            </TableCell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  </Box>
);

InvestmentFundCategory.propTypes = {
  ...investmentFundCategoryProps,
};

export default InvestmentFundCategory;
