import { ThemeProvider } from 'styled-components';

import Layout from 'layouts';
import { Head } from 'humanity/components/head';
import { theme } from 'utils/theme.v2';
import { pageSettingsType } from 'types/pages/pageContainer.types';
import { advisorsPageType } from 'humanity/pages/advisorsPage/advisorsPage.types';
import BasicMasthead from 'humanity/sections/basicMasthead';
import SimpleRichTextSection from 'humanity/sections/simpleRichTextSection';
import InvestmentFunds from 'humanity/sections/investmentFunds';
import ValuePropList from 'humanity/sections/valuePropList';

const AdvisorsPage = ({ pageSettings, pageContent }) => (
  <Layout
    type="primary"
    layout={pageSettings.layout}
    notes={pageContent.notesCollection?.items}
    disclosures={pageContent.disclosuresCollection?.items}
  >
    <Head
      title={pageSettings.seoMetadata?.title}
      description={pageSettings.seoMetadata?.description}
      url={`${process.env.NEXT_PUBLIC_HI_BASE_URL}${pageSettings.seoMetadata?.canonicalUrl}`}
      noIndex={pageSettings.seoMetadata?.noIndex}
    />
    <ThemeProvider theme={theme}>
      {pageContent.sectionsCollection?.items?.map((section) => {
        switch (section?.__typename) {
          case 'BasicMasthead':
            return (
              <BasicMasthead
                key={section?.sys?.id}
                image={section?.image}
                imageAlignment={section?.imageAlignment}
                eyebrow={section?.eyebrow}
                heading={section?.heading}
                paragraph={section?.paragraph}
                form={section?.form}
                cta={section?.cta}
              />
            );
          case 'SimpleRichTextSection':
            return (
              <SimpleRichTextSection
                key={section?.sys?.id}
                textAlignment={section?.textAlignment}
                content={section?.content}
                cta={section?.cta}
              />
            );
          case 'InvestmentFunds':
            return (
              <InvestmentFunds
                key={section.sys?.id}
                verticalPadding={section.verticalPadding}
                heading={section.heading}
                fundCategories={section.fundCategoriesCollection?.items}
              />
            );
          case 'ValuePropList':
            return (
              <ValuePropList
                key={section?.sys?.id}
                backgroundColor={section?.backgroundColor}
                topPadding={section?.topPadding}
                bottomPadding={section?.bottomPadding}
                heading={section?.heading}
                headingAlignment={section?.headingAlignment}
                subheading={section?.subheading}
                valueProps={section?.valuePropsCollection?.items}
              />
            );
          default:
            return null;
        }
      })}
    </ThemeProvider>
  </Layout>
);

AdvisorsPage.propTypes = {
  pageSettings: pageSettingsType.isRequired,
  pageContent: advisorsPageType.isRequired,
  // experimentData: PropTypes.arrayOf(
  //   PropTypes.shape({
  //     campaignTitle: PropTypes.string,
  //     campaignId: PropTypes.string,
  //     meta: PropTypes.objectOf(PropTypes.string),
  //     campaignKey: PropTypes.string,
  //     variation: PropTypes.string,
  //     hinAnonymousId: PropTypes.string,
  //   })
  // ).isRequired,
};

export default AdvisorsPage;
