import PropTypes from 'prop-types';

import { ctaType } from 'humanity/components/cta/cta.types';
import { simpleRichTextType } from 'humanity/components/simpleRichText/simpleRichText.types';
import { contentfulSysType } from 'types/common/contentfulSys.types';
import { contentfulPaddingType } from 'types/common/contentfulPadding.types';

export const simpleRichTextSectionProps = {
  topPadding: contentfulPaddingType,
  bottomPadding: contentfulPaddingType,
  textAlignment: PropTypes.oneOf(['left', 'center', 'right']).isRequired,
  content: simpleRichTextType.isRequired,
  cta: ctaType,
};

export const simpleRichTextSectionDefaultProps = {
  topPadding: 128,
  bottomPadding: 128,
  cta: null,
};

export const simpleRichTextSectionType = PropTypes.shape({
  sys: contentfulSysType.isRequired,
  ...simpleRichTextSectionProps,
});
