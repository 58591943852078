import Cta from 'humanity/components/cta';
import SimpleRichText from 'humanity/components/simpleRichText';
import TextContainer from 'humanity/components/textContainer';
import Container from 'humanity/primitives/container';
import Flex from 'humanity/primitives/flex';
import Section from 'humanity/primitives/section';
import {
  simpleRichTextSectionDefaultProps,
  simpleRichTextSectionProps,
} from 'humanity/sections/simpleRichTextSection/simpleRichTextSection.types';
import { getPaddingValues } from 'utils/getPaddingValues';

const SimpleRichTextSection = ({
  topPadding,
  bottomPadding,
  textAlignment,
  content,
  cta,
  ...props
}) => (
  <Section
    pt={getPaddingValues(topPadding)}
    pb={getPaddingValues(bottomPadding)}
    textAlign={textAlignment}
    {...props}
  >
    <Container>
      <TextContainer>
        <SimpleRichText content={content?.json} />
      </TextContainer>
      {!!cta && (
        <Flex justifyContent="center" mt={6} mx="auto">
          <Cta location="simple_rich_text_section" btnSize="lg" {...cta} />
        </Flex>
      )}
    </Container>
  </Section>
);

SimpleRichTextSection.propTypes = {
  ...simpleRichTextSectionProps,
};

SimpleRichTextSection.defaultProps = {
  ...simpleRichTextSectionDefaultProps,
};

export default SimpleRichTextSection;
