import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import Box from 'humanity/primitives/box';

const Table = forwardRef(({ stripe = true, ...props }, ref) => (
  <Box
    as="table"
    ref={ref}
    sx={{
      borderRadius: 'sm',
      borderSpacing: 0,
      bg: 'inherit',
      overflow: 'scroll',
      fontFamily: 'body',
      fontSize: 'body',
      lineHeight: 'body',
      color: 'blue100',
      '& b, & strong': {
        fontWeight: 600,
        color: 'blue100',
      },
      '& highlight': {
        fontWeight: 600,
        color: 'tealDarker',
      },
      'tbody > tr:nth-of-type(odd)': stripe
        ? {
            bg: 'backgroundLightGray2',
          }
        : {},
      tr: {
        '> *:first-child': {
          borderTopLeftRadius: 'sm',
          borderBottomLeftRadius: 'sm',
        },
        '> *:last-child': {
          borderTopRightRadius: 'sm',
          borderBottomRightRadius: 'sm',
        },
      },
    }}
    {...props}
  />
));
Table.propTypes = {
  stripe: PropTypes.bool,
  children: PropTypes.node.isRequired,
};
Table.defaultProps = {
  stripe: true,
};

Table.Header = forwardRef((props, ref) => <Box as="thead" ref={ref} {...props} />);

Table.HeaderCell = forwardRef((props, ref) => (
  <Box
    as="th"
    ref={ref}
    sx={{
      p: 3,
      fontWeight: 600,
      color: 'blue100',
      '&:first-child': { pl: 5 },
      '&:last-child': { pr: 5 },
    }}
    {...props}
  />
));

Table.SubheaderCell = forwardRef((props, ref) => (
  <Box
    as="th"
    ref={ref}
    sx={{
      py: 4,
      fontWeight: 600,
      color: 'blue100',
      textAlign: 'left',
      fontSize: 3,
    }}
    {...props}
  />
));
Table.Body = forwardRef((props, ref) => <Box as="tbody" ref={ref} {...props} />);

Table.Row = forwardRef((props, ref) => (
  <Box as="tr" ref={ref} sx={{ px: 3, bg: 'inherit' }} {...props} />
));

Table.Cell = forwardRef((props, ref) => (
  <Box
    as="td"
    ref={ref}
    sx={{
      p: 3,
      color: 'inherit',
      '&:first-child': { pl: 5 },
      '&:last-child': { pr: 5 },
    }}
    {...props}
  />
));

export default Table;
