import { forwardRef } from 'react';

import Box, { boxProps } from 'humanity/primitives/box';

const TextContainer = forwardRef(({ textAlign, ...props }, ref) => (
  <Box
    ref={ref}
    sx={{
      ml: textAlign === 'left' ? 0 : 'auto',
      mr: 'auto',
      maxWidth: 874,
      textAlign,
    }}
    {...props}
  />
));

TextContainer.displayName = 'TextContainer';

TextContainer.propTypes = {
  ...boxProps,
};

export default TextContainer;
