import PropTypes from 'prop-types';

import { investmentFundCategoryType } from 'humanity/components/investmentFundCategory/investmentFundCategory.types';
import { contentfulSysType } from 'types/common/contentfulSys.types';
import { contentfulPaddingType } from 'types/common/contentfulPadding.types';

const commonProps = {
  verticalPadding: contentfulPaddingType.isRequired,
  heading: PropTypes.string.isRequired,
};

export const investmentFundsProps = {
  ...commonProps,
  fundCategories: PropTypes.arrayOf(investmentFundCategoryType).isRequired,
};

export const investmentFundsType = PropTypes.shape({
  ...commonProps,
  sys: contentfulSysType.isRequired,
  fundCategoriesCollection: PropTypes.shape({
    items: PropTypes.arrayOf(investmentFundCategoryType).isRequired,
  }),
});
