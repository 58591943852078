import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import styled from 'styled-components';
import css from '@styled-system/css';
import { variant as styledVariant } from 'styled-system';
import { BLOCKS } from '@contentful/rich-text-types';

import {
  basicMastheadDefaultProps,
  basicMastheadProps,
} from 'humanity/sections/basicMasthead/basicMasthead.types';
import Section from 'humanity/primitives/section';
import Container from 'humanity/primitives/container';
import Box from 'humanity/primitives/box';
import Grid from 'humanity/primitives/grid';
import Flex from 'humanity/primitives/flex';
import Heading from 'humanity/primitives/heading';
import Image from 'humanity/primitives/image';
import SimpleRichText from 'humanity/components/simpleRichText';
import Cta from 'humanity/components/cta';
import Text from 'humanity/primitives/text';
import { contentfulFormType } from 'humanity/components/contentfulForm/contentfulForm.types';
import { signupStepsContainerType } from 'humanity/sections/signupStepsContainer';
import Disclosures from 'humanity/components/disclosures';

const LeadForm = dynamic(() => import('humanity/components/leadForm'));
const FastTrackMastheadForm = dynamic(() =>
  import('humanity/components/fastTrackMastheadForm').then(
    (mod) => mod.FastTrackMastheadForm
  )
);

const Form = ({ form, ...rest }) => (
  <>
    {form.__typename === 'Form' && <LeadForm form={form} {...rest} />}
    {form.__typename === 'SignupStepsContainer' && (
      <FastTrackMastheadForm form={form} {...rest} />
    )}
  </>
);

Form.propTypes = {
  form: PropTypes.oneOf([contentfulFormType, signupStepsContainerType]).isRequired,
};

const StyledSection = styled(Section)(({ theme }) =>
  styledVariant({
    variants: {
      blue100: {
        bg: 'blue100',
      },
      brightBlue10: {
        bg: 'brightBlue10',
      },
      tealGradient: {
        backgroundImage: theme.gradients.tealTeal,
      },
      white: {
        bg: 'white',
        backgroundImage: [
          `radial-gradient(circle at 20% 20%, ${theme.colors.backgroundLightGray2} 0%, ${theme.colors.backgroundLightGray2} 60%, ${theme.colors.background} 60%)`,
          null,
          `radial-gradient(circle at 70% -115%, ${theme.colors.backgroundLightGray2} 0%, ${theme.colors.backgroundLightGray2} 75%, ${theme.colors.background} 75%)`,
        ],
        backgroundPosition: 'center, center',
      },
      yellow10: {
        bg: 'yellow10',
      },
    },
  })
);

const ImageWrapper = styled(Flex)(({ imageAlignment, contentPosition }) =>
  css({
    display: [contentPosition === 'right' && 'none', null, null, 'flex'],
    gridColumnStart: ['1', null, null, contentPosition === 'right' ? '1' : '7'],
    gridColumnEnd: ['span 12', null, null, 'span 6'],
    gridRow: [contentPosition === 'right' ? '1' : '2', null, null, '1'],
    alignSelf: 'stretch',
    alignItems: imageAlignment === 'center' ? 'center' : 'flex-end',
    justifyContent: 'center',
    mt: [5, null, null, 0],
    pt: 0,
    pb: imageAlignment === 'center' ? [8, null, null, 0] : 0,
  })
);

const MobileImageWrapper = styled(ImageWrapper)(
  css({
    display: ['flex', null, null, 'none'],
  })
);

const CopyWrapper = styled(Box)(({ contentPosition }) =>
  css({
    gridColumnStart: ['1', null, null, contentPosition === 'right' ? '7' : '1'],
    gridColumnEnd: ['span 12', null, null, 'span 6'],
    gridRow: '1',
    alignSelf: 'start',
    pt: [8, null, null, 9],
    pb: [0, null, null, 9],
  })
);

const Yellow = styled.span(
  css({
    color: 'yellow100',
  })
);

const renderOpts = (fontColor) => ({
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <Text variant="lead" color={fontColor}>
        {children}
      </Text>
    ),
  },
});

const BasicMasthead = ({
  variant = 'white',
  image,
  imageAlignment,
  contentPosition = 'left',
  eyebrow = '',
  heading,
  paragraph,
  form,
  cta,
  disclosures,
}) => {
  const fontColor =
    variant === 'blue100' || variant === 'tealGradient' ? 'white' : 'blue100';
  const formVariant =
    variant === 'blue100' || variant === 'tealGradient' ? 'dark' : 'light';
  console.log('imageAlignment', imageAlignment);
  return (
    <StyledSection data-testid="BasicMasthead" variant={variant}>
      <Container>
        <Grid>
          <CopyWrapper data-testid="BasicMastheadCopy" contentPosition={contentPosition}>
            {!!eyebrow?.length && (
              <Text
                variant="eyebrow"
                fontWeight="bold"
                color={variant === 'tealGradient' ? 'white' : 'teal100'}
              >
                {eyebrow}
              </Text>
            )}
            <Heading
              as="h1"
              variant={form ? ['h3', null, null, 'h2'] : ['h3', null, null, 'h1']}
              mt={2}
              color={fontColor}
            >
              {heading}
              <Yellow>.</Yellow>
            </Heading>
            {contentPosition === 'right' && (
              <MobileImageWrapper
                data-testid="BasicMastheadImage"
                imageAlignment={imageAlignment}
              >
                {imageAlignment === 'center' && (
                  <Image
                    src={image?.url}
                    width={584}
                    height={584}
                    imgixParams={{ fit: 'crop', ar: '1:1' }}
                    quality={100}
                  />
                )}
                {imageAlignment === 'bottom' && (
                  <Image src={image?.url} width={596} height={647} quality={100} />
                )}
              </MobileImageWrapper>
            )}
            <Box mt={3}>
              <SimpleRichText
                content={paragraph?.json}
                renderOptions={renderOpts(fontColor)}
              />
            </Box>
            {form && (
              <Form
                form={form}
                submitButtonProps={{
                  width: ['100%', null, null, '32%'],
                }}
                mt={4}
                variant={formVariant}
              />
            )}
            {cta && !form && (
              <Cta
                type={cta.type}
                label={cta.label}
                url={cta.url}
                btnSize="lg"
                location="masthead"
                btnVariant={cta.variant}
                mt={5}
              />
            )}
          </CopyWrapper>
          <ImageWrapper
            data-testid="BasicMastheadImage"
            imageAlignment={imageAlignment}
            contentPosition={contentPosition}
          >
            {imageAlignment === 'center' && (
              <Image
                src={image?.url}
                width={584}
                height={584}
                imgixParams={{ fit: 'crop', ar: '1:1' }}
                quality={100}
              />
            )}
            {imageAlignment === 'bottom' && (
              <Image src={image?.url} width={596} height={647} quality={100} />
            )}
          </ImageWrapper>
        </Grid>
        {disclosures && (
          <Disclosures
            disclosures={disclosures}
            mt={imageAlignment === 'bottom' ? 5 : 0}
            mb={5}
          />
        )}
      </Container>
    </StyledSection>
  );
};

BasicMasthead.propTypes = {
  ...basicMastheadProps,
};

BasicMasthead.defaultProps = {
  ...basicMastheadDefaultProps,
};

export default BasicMasthead;
