import styled from 'styled-components';
import css from '@styled-system/css';

import Container from 'humanity/primitives/container';
import Grid from 'humanity/primitives/grid';
import Section from 'humanity/primitives/section';
import { valuePropListProps } from 'humanity/sections/valuePropList/valuePropList.types';
import Box from 'humanity/primitives/box';
import ValuePropWithImage from 'humanity/components/valuePropWithImage';
import Heading from 'humanity/primitives/heading';
import Text from 'humanity/primitives/text';
import TextContainer from 'humanity/components/textContainer';
import { getPaddingValues } from 'utils/getPaddingValues';
import Cta from 'humanity/components/cta';
import Disclosures from 'humanity/components/disclosures';

const QuadWrapper = styled(Box)(
  css({
    gridColumnStart: 'auto',
    gridColumnEnd: ['span 12', null, 'span 6'],
    mt: [3, null, 5],
  })
);

const TriWrapper = styled(Box)(
  css({
    gridColumnStart: 'auto',
    gridColumnEnd: ['span 12', null, 'span 4'],
    mt: [3, null, 5],
  })
);

const HeadingCopy = ({ heading = '', subheading = '' }) => (
  <Box mb={5}>
    {!!heading?.length && (
      <Heading
        variant={['h3', null, null, 'h2']}
        dangerouslySetInnerHTML={{ __html: heading?.trim() }}
      />
    )}
    {!!subheading?.length && (
      <Text
        mt={3}
        variant="lead"
        dangerouslySetInnerHTML={{ __html: subheading.trim() }}
      />
    )}
  </Box>
);

HeadingCopy.propTypes = {
  // eslint-disable-next-line react/require-default-props
  heading: valuePropListProps.heading,
  subheading: valuePropListProps.subheading,
};

const ValuePropList = ({
  backgroundColor = 'white',
  topPadding = 128,
  bottomPadding = 128,
  heading = '',
  headingAlignment = 'left',
  subheading = '',
  valueProps,
  cta = null,
  disclosures,
}) => (
  <Section
    pt={getPaddingValues(topPadding)}
    pb={getPaddingValues(bottomPadding)}
    bg={backgroundColor}
  >
    <Container>
      {(!!heading?.length || !!subheading?.length) && (
        <>
          {headingAlignment === 'center' && (
            <TextContainer textAlign="center">
              <HeadingCopy heading={heading} subheading={subheading} />
            </TextContainer>
          )}
          {headingAlignment !== 'center' && (
            <TextContainer textAlign="left">
              <HeadingCopy heading={heading} subheading={subheading} />
            </TextContainer>
          )}
        </>
      )}
      <Grid>
        {valueProps?.length === 4 &&
          valueProps.map((valueProp) => (
            <QuadWrapper key={valueProp.sys?.id}>
              <ValuePropWithImage
                heading={valueProp?.heading}
                content={valueProp?.content}
                image={valueProp?.image}
                cta={valueProp?.cta}
                type="withCircle"
              />
            </QuadWrapper>
          ))}
        {valueProps?.length !== 4 &&
          valueProps.map((valueProp) => (
            <TriWrapper key={valueProp.sys?.id}>
              <ValuePropWithImage
                heading={valueProp?.heading}
                content={valueProp?.content}
                image={valueProp?.image}
                cta={valueProp?.cta}
              />
            </TriWrapper>
          ))}
      </Grid>
      {cta && (
        <Cta
          mt={6}
          width={[1, null, 'auto']}
          location="value_prop_list"
          btnSize="lg"
          {...cta}
        />
      )}
      {disclosures && <Disclosures disclosures={disclosures} mt={6} />}
    </Container>
  </Section>
);

ValuePropList.propTypes = {
  ...valuePropListProps,
};

export default ValuePropList;
