import PropTypes from 'prop-types';

import { simpleRichTextType } from 'humanity/components/simpleRichText/simpleRichText.types';
import { contentfulMediaType } from 'types/common/contentfulMedia.types';
import { ctaType } from 'humanity/components/cta/cta.types';
import { contentfulSysType } from 'types/common/contentfulSys.types';

export const commonProps = {
  image: contentfulMediaType.isRequired,
  heading: PropTypes.string.isRequired,
  content: simpleRichTextType,
  cta: ctaType,
};

export const valuePropWithImageProps = {
  ...commonProps,
  type: PropTypes.oneOf(['standard', 'withCircle', 'tabbed']),
};

export const valuePropWithImageType = PropTypes.shape({
  ...commonProps,
  sys: contentfulSysType.isRequired,
});
